.slider {
  .swiper {
    @apply overflow-visible;
  }

  .swiper-slide {
    @apply h-auto;
  }
}

.slider-accordion,
.slider-stepper {
  .swiper-wrapper {
    @apply flex items-center;
  }
}

.slider-carousel {
  .swiper {
    @apply overflow-visible;
  }

  .swiper-slide {
    @apply opacity-40;
  }

  .swiper-slide-active {
    @apply opacity-100;
  }

  .swiper-slide-next {
    @apply opacity-80;
  }

  .swiper-slide-prev {
    @apply opacity-80;
  }

  .swiper-pagination-vertical {
    @apply hidden md:block;
  }
}
